import React from 'react';
import { Formik } from 'formik';
import StrategyTitleBlock from '../strategyTitleBlock/StrategyTitleBlock';
import SingleCasesForm from './SingleCasesForm';
import {
  singleCasesSettingsValidation,
  TOTAL_STEPS,
  STRATEGY_STEPS_LABELS,
  StrategySettingsFormTypes
} from 'constants/strategyProfilesAddConstants';
import s from './SingleCases.module.scss';
import {
  AddSPErrors,
  SCTypeChoice,
  SingleCasesSettings,
  SingleCasesValues,
  StrategyProfileSettingsShape
} from 'interfaces/strategyProfilesShapes';
import { FormTarget } from 'constants/strategyProfilesConstants';

export interface singleCasesProps {
  onSubmitSingleCases: (values, type) => void;
  onClearRequestErrors: () => void;
  onCancel: () => void;
  profile: StrategyProfileSettingsShape | null;
  errors: AddSPErrors;
  target?: FormTarget;
  isStepsHidden?: boolean;
  contentWrapperClass?: string;
}

const SingleCases = ({
  target,
  onSubmitSingleCases,
  onCancel,
  profile,
  errors: { profileErr },
  isStepsHidden
}: singleCasesProps): JSX.Element => {
  const singleCasesSettings = profile?.settings?.single_cases_settings;

  const initialValues: SingleCasesValues = {
    ...singleCasesSettings
  };
  const onSubmit = (values: SingleCasesValues, setSubmitting: (isSubmitting: boolean) => void) => {
    const { is_enabled, sc_type, signals_quantity, mm_percent } = values;

    const formatedValues: SingleCasesSettings = {
      is_enabled,
      sc_type: is_enabled && sc_type ? sc_type : undefined,
      signals_quantity: is_enabled && signals_quantity ? Number(signals_quantity) : undefined,
      mm_percent: is_enabled && sc_type === SCTypeChoice.SC_MM && mm_percent ? Number(mm_percent) : undefined
    };

    onSubmitSingleCases(formatedValues, StrategySettingsFormTypes.S_C_SETTINGS);
    setSubmitting(false);
  };

  return (
    <div className={s.wrapper}>
      <StrategyTitleBlock
        step={STRATEGY_STEPS_LABELS.SINGLE_CASES}
        totalSteps={TOTAL_STEPS}
        title="Single Cases"
        isStepsHidden={isStepsHidden}
      />

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={singleCasesSettingsValidation}
        onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      >
        {({ handleSubmit, isSubmitting }) => (
          <SingleCasesForm
            onCancel={onCancel}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            profileErr={profileErr}
            target={target}
          />
        )}
      </Formik>
    </div>
  );
};

export default SingleCases;
