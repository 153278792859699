import { ColumnAlignOptions } from './randomConstants';
import s from '../components/monthsStatsTable/MonthsStatsTable.module.scss';

export const COL_DATE_FORMAT = 'MMM yy';

export enum MonthsStatsTableColIds {
  ACCOUNT = 'account',
  DATA_TYPE = 'data_type',
  TOTAL = 'total',
  AVERAGE = 'average',
  AVERAGE_DAYS = 'average_days'
}

export enum MonthsStatsFilterIDs {
  DATE_FROM = 'date_from',
  DATE_TO = 'date_to'
}

export const MonthsStatsLabels = {
  [MonthsStatsFilterIDs.DATE_FROM]: 'From',
  [MonthsStatsFilterIDs.DATE_TO]: 'To'
};

export const MONTHS_STAT_DATE = [
  {
    id: MonthsStatsFilterIDs.DATE_FROM,
    label: MonthsStatsLabels[MonthsStatsFilterIDs.DATE_FROM],
    fieldName: MonthsStatsFilterIDs.DATE_FROM,
    value: ''
  },
  {
    id: MonthsStatsFilterIDs.DATE_TO,
    label: MonthsStatsLabels[MonthsStatsFilterIDs.DATE_TO],
    fieldName: MonthsStatsFilterIDs.DATE_TO,
    value: MonthsStatsFilterIDs.DATE_TO
  }
];

const MONTHS_STATS_COL_NAMES = {
  [MonthsStatsTableColIds.ACCOUNT]: 'Account',
  [MonthsStatsTableColIds.DATA_TYPE]: 'Data type',
  [MonthsStatsTableColIds.AVERAGE_DAYS]: 'Avg.Days',
  [MonthsStatsTableColIds.AVERAGE]: 'Avg.Months',
  [MonthsStatsTableColIds.TOTAL]: 'Total'
};

export const MONTHS_STATS_COLUMNS_START = [
  {
    id: MonthsStatsTableColIds.DATA_TYPE,
    title: MONTHS_STATS_COL_NAMES[MonthsStatsTableColIds.DATA_TYPE],
    align: ColumnAlignOptions.CENTER,
    width: '100px'
  }
];

export const MONTHS_STATS_COLUMNS_END = [
  {
    id: MonthsStatsTableColIds.AVERAGE_DAYS,
    title: MONTHS_STATS_COL_NAMES[MonthsStatsTableColIds.AVERAGE_DAYS],
    align: ColumnAlignOptions.CENTER,
    width: '40px'
  },
  {
    id: MonthsStatsTableColIds.AVERAGE,
    title: MONTHS_STATS_COL_NAMES[MonthsStatsTableColIds.AVERAGE],
    align: ColumnAlignOptions.CENTER,
    width: '40px'
  },
  {
    id: MonthsStatsTableColIds.TOTAL,
    title: MONTHS_STATS_COL_NAMES[MonthsStatsTableColIds.TOTAL],
    align: ColumnAlignOptions.CENTER,
    width: '40px'
  }
];

export enum MonthsResultKeys {
  RES_BY_MONTHS = 'res_by_months',
  Q_SIGNALS = 'q_signals',
  Q_PROFITS = 'q_profits',
  Q_LOSSES = 'q_losses',
  W_PERCENT = 'w_percent',
  PF = 'pf',
  RESULT_USD = 'result_usd',
  TURN_OVER = 'turn_overs',
  DEPOSIT_MONTHLY = 'deposit_monthly',
  RES_BY_MONTHS_DIFF = 'res_by_months_diff'
}

export const MonthsResultMap = {
  [MonthsResultKeys.RES_BY_MONTHS]: 'Months Res.',
  [MonthsResultKeys.Q_SIGNALS]: 'Q Signals',
  [MonthsResultKeys.Q_PROFITS]: 'Q Profits',
  [MonthsResultKeys.Q_LOSSES]: 'Q Losses',
  [MonthsResultKeys.W_PERCENT]: 'W Percent',
  [MonthsResultKeys.PF]: 'PF',
  [MonthsResultKeys.RESULT_USD]: 'Result in USD',
  [MonthsResultKeys.TURN_OVER]: 'Turnover',
  [MonthsResultKeys.DEPOSIT_MONTHLY]: 'High Water Mark',
  [MonthsResultKeys.RES_BY_MONTHS_DIFF]: 'Re-invest'
};

export const RowColors = [
  { title: '<0(%)', colorClass: s.low },
  { title: '0-5(%)', colorClass: s.lowMid },
  { title: '5-8(%)', colorClass: s.mid },
  { title: '8-15(%)', colorClass: s.midHigh },
  { title: '>15(%)', colorClass: s.high }
];

export const SignMonthMap = {
  [MonthsResultKeys.RES_BY_MONTHS]: '%',
  [MonthsResultKeys.Q_SIGNALS]: '',
  [MonthsResultKeys.Q_PROFITS]: '',
  [MonthsResultKeys.Q_LOSSES]: '',
  [MonthsResultKeys.W_PERCENT]: '%',
  [MonthsResultKeys.PF]: '',
  [MonthsResultKeys.RESULT_USD]: '$',
  [MonthsResultKeys.TURN_OVER]: '',
  [MonthsResultKeys.DEPOSIT_MONTHLY]: '$',
  [MonthsResultKeys.RES_BY_MONTHS_DIFF]: '%'
};

export const FixationMonthMap = {
  [MonthsResultKeys.RES_BY_MONTHS]: 2,
  [MonthsResultKeys.Q_SIGNALS]: 0,
  [MonthsResultKeys.Q_PROFITS]: 0,
  [MonthsResultKeys.Q_LOSSES]: 0,
  [MonthsResultKeys.W_PERCENT]: 2,
  [MonthsResultKeys.PF]: 0,
  [MonthsResultKeys.RESULT_USD]: 2,
  [MonthsResultKeys.TURN_OVER]: 0,
  [MonthsResultKeys.DEPOSIT_MONTHLY]: 0,
  [MonthsResultKeys.RES_BY_MONTHS_DIFF]: 2
};

export const OrderMonthMap = {
  [MonthsResultKeys.DEPOSIT_MONTHLY]: 1,
  [MonthsResultKeys.RES_BY_MONTHS]: 2,
  [MonthsResultKeys.RES_BY_MONTHS_DIFF]: 3,
  [MonthsResultKeys.RESULT_USD]: 4,
  [MonthsResultKeys.Q_SIGNALS]: 5,
  [MonthsResultKeys.Q_PROFITS]: 6,
  [MonthsResultKeys.Q_LOSSES]: 7,
  [MonthsResultKeys.W_PERCENT]: 8,
  [MonthsResultKeys.PF]: 9,
  [MonthsResultKeys.TURN_OVER]: 10
};

export const HiddenTotalValue = [MonthsResultKeys.PF, MonthsResultKeys.W_PERCENT, MonthsResultKeys.DEPOSIT_MONTHLY];
export const HideColoredTooltips = [MonthsResultKeys.RES_BY_MONTHS, MonthsResultKeys.RES_BY_MONTHS_DIFF];

export enum StatisticTableColIds {
  PERIOD = 'period',
  HWM = 'high_watermark',
  DEPOSIT = 'deposit_end_of_period',
  RETURN_PERCENT_HWM = 'return_percent_high_watermark_unrealized',
  RETURN_FIAT_HWM = 'return_fiat_high_watermark_unrealized',
  RETURN_PERCENT_PNL = 'return_percent_realized',
  RETURN_FIAT_PNL = 'return_fiat_realized',
  REINVEST = 'reinvest_percent_realized',
  WINRATE = 'winrate_percent',
  PROFIT_FACTOR = 'profit_factor',
  ALL_SIGNALS = 'all_signals_quantity',
  PROFIT_SIGNALS = 'profit_signals_quantity',
  LOSS_SIGNALS = 'loss_signals_quantity',
  TURN_OVER = 'turn_over'
}

const STATISTIC_TABLE_COL_NAMES = {
  [StatisticTableColIds.PERIOD]: 'Period',
  [StatisticTableColIds.HWM]: 'HWM',
  [StatisticTableColIds.DEPOSIT]: 'Deposit end of period',
  [StatisticTableColIds.RETURN_PERCENT_HWM]: 'Return % HWM',
  [StatisticTableColIds.RETURN_FIAT_HWM]: 'Return $ HWM',
  [StatisticTableColIds.RETURN_PERCENT_PNL]: 'Return % R-PnL',
  [StatisticTableColIds.RETURN_FIAT_PNL]: 'Return $ R-PnL',
  [StatisticTableColIds.REINVEST]: 'Re-invest % R-PnL',
  [StatisticTableColIds.WINRATE]: 'WR%',
  [StatisticTableColIds.PROFIT_FACTOR]: 'PF',
  [StatisticTableColIds.ALL_SIGNALS]: 'All signals',
  [StatisticTableColIds.PROFIT_SIGNALS]: 'Profit signals',
  [StatisticTableColIds.LOSS_SIGNALS]: 'Loss signals',
  [StatisticTableColIds.TURN_OVER]: 'Turn Over'
};

export const STATISTIC_TABLE_COL = [
  {
    id: StatisticTableColIds.PERIOD,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.PERIOD],
    align: ColumnAlignOptions.CENTER,
    sortable: true
  },
  {
    id: StatisticTableColIds.HWM,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.HWM],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.DEPOSIT,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.DEPOSIT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.RETURN_PERCENT_HWM,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.RETURN_PERCENT_HWM],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.RETURN_FIAT_HWM,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.RETURN_FIAT_HWM],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.RETURN_PERCENT_PNL,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.RETURN_PERCENT_PNL],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.RETURN_FIAT_PNL,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.RETURN_FIAT_PNL],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.REINVEST,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.REINVEST],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.WINRATE,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.WINRATE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.PROFIT_FACTOR,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.PROFIT_FACTOR],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.ALL_SIGNALS,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.ALL_SIGNALS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.PROFIT_SIGNALS,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.PROFIT_SIGNALS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.TURN_OVER,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.TURN_OVER],
    align: ColumnAlignOptions.CENTER
  }
];

export const STATISTIC_CLIENT_TABLE_COL = [
  {
    id: StatisticTableColIds.PERIOD,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.PERIOD],
    align: ColumnAlignOptions.CENTER,
    sortable: true
  },
  {
    id: StatisticTableColIds.HWM,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.HWM],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.DEPOSIT,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.DEPOSIT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.RETURN_PERCENT_HWM,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.RETURN_PERCENT_HWM],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.RETURN_FIAT_HWM,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.RETURN_FIAT_HWM],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.RETURN_PERCENT_PNL,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.RETURN_PERCENT_PNL],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.RETURN_FIAT_PNL,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.RETURN_FIAT_PNL],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.REINVEST,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.REINVEST],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.WINRATE,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.WINRATE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.PROFIT_FACTOR,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.PROFIT_FACTOR],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: StatisticTableColIds.TURN_OVER,
    title: STATISTIC_TABLE_COL_NAMES[StatisticTableColIds.TURN_OVER],
    align: ColumnAlignOptions.CENTER
  }
];
