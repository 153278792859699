import { useFormikContext } from 'formik';
import { ENABLE, SC_SKIP, SC_TYPE, SC_MM, SIGNALS_QUANTITY, MM_PERCENT } from 'constants/strategyProfilesAddConstants';
import { SingleCasesValues } from 'interfaces/strategyProfilesShapes';

export const useSingleCases = () => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, submitCount } =
    useFormikContext<SingleCasesValues>();

  const getIsError = (id) => !!errors[id] && (touched[id] || submitCount > 0);
  const getErrorMsg = (id) => (touched[id] || submitCount > 0 ? errors[id] : '');
  const getIsValid = (id) => !errors[id] || !(touched[id] || submitCount > 0);

  const enabled = values[ENABLE];
  const scType = values[SC_TYPE];
  const scSkip = values[SC_SKIP];
  const mmSkip = values[SC_MM];
  const signalsAmount = values[SIGNALS_QUANTITY];
  const mMPErcent = values[MM_PERCENT];

  const handleEnableChange = (event) => {
    const checked = event.target.checked;
    setFieldValue(ENABLE, checked);
    if (!checked) {
      setFieldValue(SC_SKIP, false);
      setFieldValue(SC_MM, false);
      setFieldValue(SIGNALS_QUANTITY, undefined);
      setFieldValue(MM_PERCENT, undefined);
    }
  };

  return {
    enabled,
    scType,
    scSkip,
    mmSkip,
    signalsAmount,
    mMPErcent,
    handleChange,
    handleBlur,
    handleEnableChange,
    setFieldValue,
    getIsError,
    getErrorMsg,
    getIsValid,
    errors,
    submitCount
  };
};
